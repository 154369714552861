<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import CheckCustomer from './components/check_customer.vue';

formCreate.component('CheckCustomerDiscount', CheckCustomer);

export default {
  extends: Form,
  data() {
    return {
      rule: [],
    };
  },
  components: {
    formCreate: formCreate.$form(),
  },
  async mounted() {
    this.rule = await this.getBeforFormRule('dms_discount_cost_pool_manual_entry');
    const rule = [];
    this.rule.forEach((v) => {
      const rowData = v;
      if (rowData.field === 'customerName') {
        // rowData.type = 'CheckCustomer';
        rowData.restful = '/mdm/mdmCustomerMsgController/page';
        rowData.props = {
          ...rowData.props,
          remoteParams: 'customerName',
          remote: true,
          filterable: true,
        };
        rowData.optionsKey = {
          value: 'customerCode',
          label: 'customerName',
        };
        rowData.on = {
          change: (val) => this.setcustomerName(val, rowData.options),
        };
      }
      if (rowData.field === 'saleCompanyCode') {
        rowData.on = {
          ...rowData.on,
          change: (val) => {
            this.setValue({
              saleCompanyName: rowData.options.filter((a) => a.dictCode === val)[0].dictValue,
            });
          },
        };
      }
      if (rowData.field === 'customerCode') {
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      if (rowData.field === 'operationType') {
        rowData.props = {
          ...rowData.props,
          disabled: !this.formConfig.isDetail,
        };
      }
      if (rowData.field === 'payType') {
        rowData.props = {
          ...rowData.props,
          disabled: true,
        };
      }
      if (rowData.field === 'files') {
        rowData.props = {
          ...rowData.props,
          showFileList: true,
          uploadType: 'file',
          listType: 'text',
          fileMsg: '只能上传pdf/word/excel/ppt/png/jpg文件',
          accept: '.pdf,.doc,.docx,.xlsx,.xls,.ppt,.pptx,.png,.jpg',
        };
      }
      rule.push(rowData);
    });
    this.rule = [];
    this.rule = rule;
    this.reload(rule);
    this.afterReload();
    this.getSomeOptions();
  },
  methods: {
    afterReload() {

    },
    // 获取支付方式，费用池类型，调整类型等options
    getSomeOptions() {
      let path = '/fee/feePoolDiscountController/queryForAdjust';
      if (this.formConfig.isDetail) {
        path = '/fee/feePoolDiscountController/queryForAdjustDetail';
      }
      request
        .get(path)
        .then((res) => {
          if (res.success) {
            const operationType = this.getRule('operationType');
            operationType.options = res.result.operationTypeSelectList.map((v) => ({
              value: v.value,
              label: v.text,
            }));
            const payType = this.getRule('payType');
            payType.options = res.result.payTypeList.map((v) => ({
              value: v.value,
              label: v.text,
            }));
            const discountType = this.getRule('discountType');
            discountType.options = res.result.discountTypeList.map((v) => ({
              value: v.value,
              label: v.text,
            }));
            this.setValue({
              operationType: res.result.operationTypeSelectList[0].value,
              payType: res.result.payTypeList[0].value,
            });
          }
        });
    },
    setcustomerName(val, options) {
      this.customerName = options.find((v) => v.customerCode === val).customerName;
      this.setValue({
        customerCode: val,
      });
    },
    // 提交
    async submit() {
      if (!this.getFormData()) return false;
      const params = {
        ...this.getFormData(),
        customerName: this.customerName,
      };
      delete params.list;
      let path = '/fee/feePoolDiscountController/adjust';
      if (this.formConfig.isDetail) {
        path = '/fee/feePoolDetailController/adjust';
      }
      // return false;
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      return true;
    },
  },
};
</script>
